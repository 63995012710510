import bgm from 'audio/bgm.mp3';
import bgImage from 'images/brand/intro-bg.jpg';
import bgImageMobile from 'images/brand/intro-bg-m.jpg';
import logo from 'images/brand/logo.png';

import type { Intro as MenuIntro } from 'components/molecules/Menu';

import type { ImageAlign, TourData } from 'utils/types/customization';

interface ModelConfig {
    tools: {
        info: boolean;
        infoDefault?: boolean;
        position: boolean;
        positionDefault?: boolean;
        highlight: boolean;
        tour: boolean;
        view: boolean;
        floors: boolean;
        share: boolean;
        screenshot: boolean;
        getLocation: boolean;
    };
    logo?: string;
    menu: boolean;
    music?: string;
    intro?: MenuIntro[];
    displayTourList?: string[];
    defaultTour: string;
    defaultTitle: string;
    tourData: {
        [key: string]: TourData;
    };
    fixedLink: {
        text: string;
        url: string;
    };
}

const modelConfig: ModelConfig = {
    tools: {
        info: true,
        infoDefault: true,
        position: true,
        positionDefault: false,
        view: true,

        // tour: true,
        // highlight: true,
        // floors: true,
        tour: false,
        highlight: false,
        floors: false,
        share: true,
        screenshot: false,
        getLocation: false,
    },
    logo,
    music: bgm,
    menu: true,
    intro: [
        {
            type: 'ZH',
            content: `展覽總說

            當代高僧、淨宗一代尊宿 淨空老法師於2022年7月26日安詳示寂，享耆壽96齡，畢生遵佛教誨，上行下化，開演十方諸佛同讚之淨土法門。

            老法師講經逾一甲子歲月，教化眾生無數，將淨土宗弘揚到全世界。他的身教，真正落實「學為人師、行為世範」，也圓滿的演繹「上報四重恩」，為我們做出了知恩報恩的最佳典範。

            淨空老法師弘法足跡遍五大洲，所到之處皆為教學，利益眾生，不遺餘力。率先利用視聽多媒體，於網路、衛星電視弘法，法音普及全球。在世界各地倡建淨宗學會，專修專弘淨土法門。倡印《大藏經》、儒佛各宗經典，製作影音資料，無償流通全世界。主持弘法人才培訓班，培養講經弘法人才。倡建、贊助國際佛教大學，復興三大語系佛教。

            老法師心懷「為往聖繼絕學，為萬世開太平」的弘願，致力復興傳統文化；於世界各地提倡祭祖，建立萬姓先祖紀念堂，流通與保存《四庫全書》、《四庫全書薈要》、《群書治要》等經典，建立馬來西亞及英國漢學院，培養承傳漢學的師資人才。

            提倡世界宗教是一家，以仁慈博愛為共同核心。以身作則，學習不同宗教的教義，並節錄精華，出版流通，便於各宗教互相學習。他將宗教團結理念帶入聯合國教科文組織，其後並成立辦公室，藉此把中國傳統教育與宗教愛的教育介紹給各國大使。為促進宗教團結、社會安定、世界和平做出貢獻。

            「覺光遠照—淨空老法師文物展」從「淨公道影」的單元中，特別精選老法師各個時期重要照片，以彰顯「教學為先」及「和平使者」兩個部分的事功，並與大事記年表的歷史照片互相呼應連結。每張照片都訴說著老法師生平每一階段中的因緣故事。

            「文獻手稿」單元中，特別選錄展品「韓鍈館長的願望」，就是感念其護持老法師講經三十年，知恩報恩的恩義人生。另一「往來信札」單元中，極為難得能夠徵集到老法師與簡豐文居士，共創佛陀教育基金會時期的往來書信。藉此更能一窺老法師循著釋迦牟尼佛走過的路，致力於講經教學，遍及世界各地的弘法足跡。

            「法水長流」這一單元，呈現出來老法師墨寶遍天下，字字是正法的文化內涵。他為人講經說法之餘，生活的日常，也以書法墨跡傳授佛陀覺行圓滿的清淨智慧。老法師致贈親自書寫之墨寶，廣結善緣，見聞者莫不歡喜無量，視若珍寶。

            「文物留珍」，展出老法師常使用的袈裟、念珠、講經架等文物，並陳設情境式的展區，讓我們得以重溫過往聆聽老法師講經教學的日子。現場也展示了老法師有感於古人保存佛法之用心，大量印製流通佛儒經典，並依古本精印的《乾隆大藏經》、《四庫全書》、《四庫全書薈要》、《群書治要》等重要典籍，分贈世界各地寺院、大學、圖書館珍藏。

            在老法師圓寂週年之際，我們希望藉此展覽，向大眾展現老法師履踐佛陀聖哲教誨的誠敬人生。`,
        },
    ],

    displayTourList: ['tour'],
    defaultTour: 'tour',
    defaultTitle: '覺光遠照 淨空老法師文物展',
    tourData: {
        tour: {
            text: '覺光遠照',
            path: '覺光遠照',
            title: '覺光遠照',
            cover: bgImage,
            coverMobile: bgImageMobile,

            modelId: '2AMsR69AP6h',
            sr: '-3.14',
            ss: '177',

            sweepId: '48c08035d97a4bce916b849a7c8ecdb8',
            position: { x: -9.782379150390625, y: 1.5201735496520996, z: -7.912735939025879 },
            rotation: { x: 0.3274917207006876, y: -179.73396927092986 },
        },
    },
    fixedLink: {
        text: '淨空老法師專集網',
        url: 'https://www.amtb.tw/#/home',
    },
};

export const imageAlignConfig: {
    default: ImageAlign;
    exceptions: {
        [key: string]: ImageAlign;
    };
} = {
    default: 'center',
    exceptions: {},
};

export default modelConfig;
